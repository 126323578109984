<template>
    <div>
        <input ref="profilePicInput" style="display: none" @change="profilePictureChanged" accept="image/*" type="file">
        <div
            :style="{...{backgroundImage: 'url('+profilePicture+'?nocache='+Date.now()+')'}, ...(customStyles ? customStyles : {})}"
            id="profile-picture" @click="$refs.profilePicInput.click()">
            <div id="profile-picuter-hover">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil"
                     viewBox="0 0 16 16">
                    <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import helper from '../helper'

export default {
    name: "profile-picture",
    props: [
        'profilePicture', "uploadUrl", "customStyles"
    ],
    mounted() {

    },
    methods: {
        profilePictureChanged(event) {
            var data = new FormData()

            data.append('file', event.target.files[0])

            fetch(process.env.VUE_APP_API_BASE + this.uploadUrl, {
                method: 'PUT',
                headers: {
                    "x-auth-key": this.apiClient.options.header["x-auth-key"]
                },
                body: data
            }).then(() => {
                this.$emit("uploaded")
                event.target.value = null
                helper.showSnackBar("Done", '#56ff5e')
            })
        }
    }
}
</script>
<style lang="scss" scoped>
#profile-picture {
    width: 80px;
    height: 80px;
    margin-top: 10px;
    background-size: cover;
    background-position: cover;
    border: #dfdfdf solid 2.5px;
    border-radius: 13px;
    cursor: pointer;

    #profile-picuter-hover {
        visibility: hidden;
        transition: backgdrop-filter 0.3s;
        backdrop-filter: blur(0px);

        svg {
            mix-blend-mode: difference;
            transition: filter 0.3s;
            filter: blur(43px);
        }
    }

    &:hover #profile-picuter-hover {
        display: block;
        visibility: visible;
        border-radius: 10.5px;
        background: #00000026;
        backdrop-filter: blur(0.7px);
        height: 100%;

        svg {
            filter: none;
            margin: 25px;
            color: #FFF;
            width: 25px;
            height: 25px;
        }
    }
}
</style>