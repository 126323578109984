<template>
    <div>
        <div class="contents">
            <Modal mtitle="Contacts" ref="contactsModel">
                <div id="contacts-model">
                    <h3>Contacts</h3>
                    <div class="friend-list">
                        <div v-for="contact of contacts" :key="contact.id">
                            <a class="button delete" @click="removeContact(contact.id)">Remove</a>
                            <img :src="contact.profile_picture">
                            <h4>{{ contact.name }}</h4>
                        </div>
                    </div>
                    <h3>Incoming contact-requests</h3>
                    <div class="friend-list">
                        <div v-for="contact of contactRequests" :key="contact.id">
                            <a class="button" @click="acceptContact(contact.id)">Accept</a>
                            <img :src="contact.profile_picture">
                            <h4>{{ contact.name }}</h4>
                        </div>
                    </div>
                    <h3>Add contact</h3>
                    <div>
                        <input style="display: inline-block; width: 70%" type="text" class="input"
                               v-model="newContactName" placeholder="Username">
                        <a class="button" style="width: 27%; margin-left: 3%" @click="addContact()">Send</a>
                    </div>
                    <br>
                </div>
            </Modal>
            <a class="button" id="contact-button" @click="$refs.contactsModel.open()">
                <i class="uil uil-users-alt"></i>
                <span>Contacts</span>
            </a>

            <div class="page-title">
                <i class="uil uil-user"></i>
                <h1>Profile</h1>
            </div>
            <div id="profile-settings">
                <div class="profile-option">
                    <span class="label">PROFILE PICTURE</span>
                    <picture-upload ref="profilePictureUpload" :profilePicture="profilePicture"
                                    uploadUrl="/api/v2/user/profile_picture"/>
                </div>

                <div class="profile-option">
                    <span class="label">USERNAME</span>
                    <input class="profile-option-value" placeholder="Username" :value="$store.state.user.name" readonly
                           type="text">
                </div>
                <div class="profile-option">
                    <span class="label">E-MAIL</span>
                    <input class="profile-option-value" placeholder="E-Mail" :value="$store.state.user.mail" readonly
                           type="text">
                </div>
                <br>
                <div class="profile-option">
                    <span class="label">FULL NAME (DISPLAY-NAME)</span>
                    <input class="profile-option-value" placeholder="Full Name" v-model="fullName" spellcheck="no"
                           type="text">
                </div>
                <div class="profile-option">
                    <span class="label">BIRTDAY</span>
                    <input class="profile-option-value" placeholder="Birtday" v-model="birthday" type="date">
                </div>
                <div class="profile-option">
                    <span class="label">FAVORITE COLOR</span>
                    <label for="color-picker" style="margin-top: 5px; display: block">
                        <div class="color-picker-indicator" :style="{'background-color': color}"></div>
                        <div class="profile-option-value"
                             style="display: inline; vertical-align: middle; margin-left: 10px">{{ color }}
                        </div>
                    </label>

                    <input type="color" v-model="color" name="colorpicker" id="color-picker"
                           style="width: 0px; height: 0px; visibility: hidden">
                </div>
                <div class="profile-option">
                    <span class="label">DESCRIPTION</span>
                    <textarea class="profile-option-value" placeholder="Description" v-model="description"
                              type="text"></textarea>
                </div>


                <router-link :to="'/switch?redirect='+encodeURIComponent($route.fullPath)" class="button no-bg"
                             style="padding: 6px 0px; color: #545454">
                    Switch Account
                </router-link>

                <a class="button right margin-top" @click="save">Save</a>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from "../../components/Modal";
import PictureUpload from '../../components/PictureUpload.vue';
import helper from "../../helper";

export default {
    data: function () {
        return {
            fullName: this.$store.state.user.full_name,
            color: this.$store.state.user.favorite_color,
            profilePicture: this.$store.state.user.profile_picture,
            description: this.$store.state.user.description,
            birthday: this.$store.state.user.birthday,
            contacts: [],
            contactRequests: [],
            newContactName: ""
        }
    },
    components: {
        Modal,
        'picture-upload': PictureUpload
    },
    watch: {
        '$store.state.user'(to) {
            this.profilePicture = to.profile_picture
            this.color = to.favorite_color
            this.description = to.description
            this.birthday = to.birthday
            this.fullName = to.full_name
        }
    },
    mounted() {
        this.$refs.contactsModel.onOpen = () => {
            this.loadContacts()
        }

        this.$refs.profilePictureUpload.$on("uploaded", () => {
            this.profilePicture = ""
            this.loadProfile()
        })
    },
    methods: {
        save() {
            const data = {}
            if (this.description != this.$store.state.user.description)
                data.description = this.description

            if (this.color != this.$store.state.user.favorite_color)
                data.favorite_color = this.color
            console.log(this.birthday, this.$store.state.user.birthday, this.birthday != this.$store.state.user.birthday);
            if (this.birthday != this.$store.state.user.birthday)
                data.birthday = this.birthday

            if (this.fullName != this.$store.state.user.full_name)
                data.full_name = this.fullName

            this.apiClient.put("/api/v2/user", data)
                .then(this.apiClient.errorCheck)
                .then(res => {
                    console.log(res);
                    if (res.success) {
                        this.loadProfile()
                        helper.showSnackBar("Done", '#56ff5e')
                    }
                }).catch(() => {
                helper.showSnackBar("Error while saving", '#EE3232')
            })
        },
        loadContacts() {
            this.apiClient.get("/api/v2/contacts")
                .then(this.apiClient.errorCheck)
                .then(res => this.contacts = res.data)

            this.apiClient.get("/api/v2/contacts/requests")
                .then(this.apiClient.errorCheck)
                .then(res => this.contactRequests = res.data)
        },
        loadProfile() {
            this.apiClient.get("/api/v2/user")
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        this.$store.state.user = res
                        this.$store.state.user.logged_in = true;
                    }
                })
        },
        addContact() {
            this.apiClient.post("/api/v2/contacts", {
                name: this.newContactName
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        helper.showSnackBar("Done", '#56ff5e')
                        this.newContactName = ""
                        this.loadContacts()
                    } else {
                        helper.showSnackBar("Error while sending request", '#f53939')
                    }
                })
        },
        acceptContact(userId) {
            this.apiClient.get("/api/v2/contacts/requests/" + userId + "/accept").then(res => res.json())
                .then(res => {
                    if (res.success) {
                        helper.showSnackBar("Done", '#56ff5e')
                        this.loadContacts()
                    } else {
                        helper.showSnackBar("Error while accepting", '#f53939')
                    }
                })
        },
        removeContact(userId) {
            this.apiClient.delete("/api/v2/contacts/" + userId).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        helper.showSnackBar("Done", '#56ff5e')
                        this.loadContacts()
                    } else {
                        helper.showSnackBar("Error while removing contact", '#f53939')
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
#profile-settings {
    .profile-option {
        margin-top: 20px;

        .label {
            display: block;
            color: #878787;
        }

        .profile-option-value {
            color: #434343;
            font-size: 23px;
            border: none;
            outline: none;
            display: block;
            margin-top: 5px;
            width: 100%;
        }
    }
}

.color-picker-indicator {
    display: inline-block;
    height: 27px;
    width: 27px;
    border-radius: 5px;
    vertical-align: middle;
    box-shadow: 0px 1px 6px 0px #00000035;
}

#contact-button {
    float: right;
}

#contacts-model {
    h3 {
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .friend-list {
        div {
            img {
                width: 45px;
                height: 45px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 30px;
                border: #00000011 solid 2px;
            }

            h4 {
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
                margin-left: 10px;
                color: #545454;
            }

            .button {
                float: right;
            }
        }
    }
}
</style>